import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const CODER = [

];
export const CODERM = [{
  path: '/CODERM',
  component: Layout,
  redirect: '/CODERM/codePage/',
  name: '编码规则',
  menuName: '编码规则',
  meta: {
    title: "编码规则",
    icon: "fa fa-tasks",
  },
  children: [
    {
      path: 'codePage',
      component: () => import('@/views/CodeRuleManagement/coderm/codeManage/codePage'),
      name: '编码规则',
      menuName: '编码规则',
      meta: {
        title: "编码规则",
        activeMenu:"/CODERM"
      },
    },
  ]
}, ];
