// 手机号
export const phone = /^((\+86|\+86\-)|(86|86\-)|(0086|0086\-))?1[3|5|7|8]\d{9}$/

// 邮箱
// export const email = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
export const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


// 网址
export const url = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/

// 传真
export const chuanZh = /^(\d{3,4}-)?\d{7,8}$/

// 身份证号
export const idCard = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

// QQ
export const QQ =  /^[1-9][0-9]{4,9}$/

// 汉字
export const CH = /[\u4E00-\u9FA5]/g

// 性别
export const sex = /^男$|^女$/

// 年月日
export const age = /^\d{4}-\d{2}-\d{2}$/

/**
 * @param {string} path
 * @returns {Boolean}
 * 是否链接
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// 正整数
export const num = /^[0-9]*[1-9][0-9]*$/
