import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const SUPPLIE = [
  {
    path: '/SUPPLIE',
    component: Layout,
    redirect: '/SUPPLIE/supplier/',
    name: '供应商S',
    children: [
      {
        path: 'supplier',
        component: MainLayout,
        name: '供应商SS',
        icon: 'zonghe',
        redirect: '/SUPPLIE/supplier/supplierAttributes',
        children: [
          { path: 'supplierAttributes', component:  () => import('@/views/SupplierFactory/supplier/supplierAttributes'), name: '供应商列表s', icon: 'zonghe' },
        ]
      },
    ]
  },
];
export const SUPPLIER = [

  {
    path: '/SUPPLIER',
    component: Layout,
    redirect: '/SUPPLIER/supplierList/',
    name: '供应商',
    menuName:'供应商',
    meta: {
      title: "供应商",
      icon: "fa fa-shield",
    },
    children: [
      { path: 'supplierList', component:  () => import('@/views/SupplierFactory/supplier/supplierList'), name: '供应商',menuName:'供应商',meta:{title:"供应商",activeMenu:"/SUPPLIER"} },
    ]
  },
];
