import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const DSNREVIE = [


];
export const DSNREVIEW = [

  {
    path: '/DSNREVIEW',
    component: Layout,
    redirect: '/DSNREVIEW/dataReview/',
    name: '设计审查',
    menuName:'设计审查',
    meta: {
      title: "设计审查",
      icon: "fa fa-retweet",
    },
    children: [
      { path: 'dataReview', component:  () => import('@/views/DSNREVIEW/captureReview/dataReview'), name: '设计内容审查',menuName:'设计审查',meta:{title:"设计审查"} },
      { path: 'gongjinDataReview', component:  () => import('@/views/DSNREVIEW/gongJinReview/GongJinDataReview'), name: '设计审查-共进电子',menuName:'设计审查-共进电子',meta:{title:"设计审查-共进电子"} },
      { path: 'reviewSet', component:  () => import('@/views/DSNREVIEW/captureReview/reviewSet'), name: '审查配置',menuName:'审查配置',meta:{title:"审查配置"}},
      { path: 'bomSelfCheck', component:  () => import('@/views/DSNREVIEW/captureReview/BomSelfCheck'), name: 'BOM自检',menuName:'BOM自检',meta:{title:"BOM自检"}},
      { path: 'bomMutualCheck', component:  () => import('@/views/DSNREVIEW/captureReview/BomMutualCheck'), name: 'BOM互检',menuName:'BOM互检',meta:{title:"BOM互检"}},
      { path: 'bomEdit', component:  () => import('@/views/DSNREVIEW/captureReview/BomEdit'), name: 'BOM编辑',menuName:'BOM编辑',meta:{title:"BOM编辑"}},
    ]
  },
];
