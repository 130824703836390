import Layout from "@/views/layout/index";

export const MENTORLIB = [
    {
        path: '/MENTORLIB',
        component: Layout,
        redirect: '/MENTORLIB/mentorList',
        name: 'Mentor中心库',
        menuName: 'Mentor中心库',
        meta: {
            title: "Mentor中心库",
            icon: "fa fa-cubes",
        },
        children: [
            {
                path: 'mentorList',
                component: () => import('@/views/mentorCenterLib/MentorCenterLib'),
                name: 'Mentor中心库',
                menuName: 'Mentor中心库',
                meta: {title: "Mentor中心库"}
            }
        ]
    },
];
